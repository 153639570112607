import * as React from "react";

const Report = () => {
  return (
    <>
      <div>
        <h1>Report</h1>
      </div>
    </>
  );
};

export default Report;
