import ReactDOM, { Container } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "axiosConfig";

import Layout from "pages/phishee/Layout";
import Home from "pages/landing/Home";
import Login from "pages/Login";
import Dashboard from "pages/phishee/Dashboard";
import SignUp from "pages/SignUp";
import AdminLogin from "pages/admin/AdminLogin";
import AdminDashboard from "pages/admin/AdminDashboard";
import AdminLayout from "pages/admin/AdminLayout";
import Tier1TemplateGenerator from "pages/admin/Tier1TemplateGenerator";
import Tier2TemplateGenerator from "pages/admin/Tier2TemplateGenerator";
import EmailTemplateList from "pages/admin/EmailTemplateList";
import EmailTemplateDetail from "pages/admin/EmailTemplateDetail";
import { LoadingProvider } from "components/LoadingProvider";
import AccountList from "pages/admin/AccountList";
import AccountDetail from "pages/admin/AccountDetail";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme } from "style";
import PhisheeList from "pages/admin/PhisheeList";
import PhisheeDetail from "pages/admin/PhisheeDetail";
import EmailList from "pages/admin/EmailList";
import EmailDetail from "pages/admin/EmailDetail";
import Report from "pages/phishee/Report";
import ReportLayout from "pages/phishee/ReportLayout";
import { DesignSystem } from "pages/private/DesignSystem";
import PhisheeLayout from "pages/phishee/PhisheeLayout";
import AdminHome from "pages/admin/AdminHome";
import EmailClick from "pages/phishee/EmailClick";
import Invite from "pages/account/Invite";
import EmailReport from "pages/phishee/EmailReport";
import AccountDashboard from "pages/account/Dashboard";
import AccountLayout from "pages/account/AccountLayout";
import AccountUserList from "pages/account/AccountUserList";
import AccountUserDetail from "pages/account/AccountUserDetail";
import AccountEmailList from "pages/account/AccountEmailList";
import AccountEmailDetail from "pages/account/AccountEmailDetail";
import AccountSettings from "pages/account/AccountSettings";
import AccountCampaigns from "pages/account/AccountCampaigns";
import ResetPasswordVerify from "pages/account/PasswordResetVerify";
import ResetPassword from "pages/account/PasswordReset";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    display1: React.CSSProperties;
    display2: React.CSSProperties;
    display3: React.CSSProperties;
    display4: React.CSSProperties;
    display5: React.CSSProperties;
    display6: React.CSSProperties;
    xl: React.CSSProperties;
    lg: React.CSSProperties;
    md: React.CSSProperties;
    sm: React.CSSProperties;
    xs: React.CSSProperties;
    smallLabel: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display1?: React.CSSProperties;
    display2?: React.CSSProperties;
    display3?: React.CSSProperties;
    display4?: React.CSSProperties;
    display5?: React.CSSProperties;
    display6?: React.CSSProperties;
    xl?: React.CSSProperties;
    lg?: React.CSSProperties;
    md?: React.CSSProperties;
    sm?: React.CSSProperties;
    xs?: React.CSSProperties;
    smallLabel?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2: true;
    display3: true;
    display4: true;
    display5: true;
    display6: true;
    xl: true;
    lg: true;
    md: true;
    sm: true;
    xs: true;
    smallLabel: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    // xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

export default function App() {
  return (
    <CookiesProvider>
      <LoadingProvider>
        <ThemeProvider theme={createTheme(theme)}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
              </Route>
              {/* Account */}
              <Route path="/login" element={<Layout />}>
                <Route index element={<Login />} />
              </Route>
              <Route path="/reset-password" element={<Layout />}>
                <Route index element={<ResetPasswordVerify />} />
              </Route>
              <Route path="/reset-password/:tokenUuid" element={<Layout />}>
                <Route index element={<ResetPassword />} />
              </Route>
              <Route path="/signup" element={<Layout />}>
                <Route index element={<SignUp />} />
              </Route>
              <Route path="/account-invite/:inviteId" element={<Layout />}>
                <Route index element={<Invite />} />
              </Route>
              <Route path="/account" element={<Layout />}>
                <Route
                  index
                  element={<Navigate to="/account/dashboard" replace={true} />}
                />
              </Route>
              <Route path="/account/dashboard" element={<AccountLayout />}>
                <Route index element={<AccountDashboard />} />
              </Route>
              <Route path="/account/users" element={<AccountLayout />}>
                <Route index element={<AccountUserList />} />
              </Route>
              <Route path="/account/users/:userId" element={<AccountLayout />}>
                <Route index element={<AccountUserDetail />} />
              </Route>
              <Route path="/account/users/new" element={<AccountLayout />}>
                <Route index element={<AccountUserDetail />} />
              </Route>
              <Route path="/account/emails" element={<AccountLayout />}>
                <Route index element={<AccountEmailList />} />
              </Route>
              <Route
                path="/account/emails/:emailId"
                element={<AccountLayout />}
              >
                <Route index element={<AccountEmailDetail />} />
              </Route>
              <Route path="/account/settings" element={<AccountLayout />}>
                <Route index element={<AccountSettings />} />
              </Route>
              <Route path="/account/campaigns" element={<AccountLayout />}>
                <Route index element={<AccountCampaigns />} />
              </Route>
              {/* Phishee */}
              <Route path="/email/:emailId" element={<ReportLayout />}>
                <Route index element={<Report />} />
              </Route>
              <Route path="/email/:emailId/click" element={<ReportLayout />}>
                <Route index element={<EmailClick />} />
              </Route>
              <Route path="/email/:emailId/report" element={<ReportLayout />}>
                <Route index element={<EmailReport />} />
              </Route>
              <Route path="/dashboard" element={<PhisheeLayout />}>
                <Route index element={<Dashboard />} />
              </Route>
              {/* Private */}
              <Route path="/typography">
                <Route index element={<DesignSystem />} />
              </Route>
              {/* Admin  */}
              <Route path="/admin" element={<Layout />}>
                <Route index element={<AdminHome />} />
              </Route>
              <Route path="/admin/login" element={<Layout />}>
                <Route index element={<AdminLogin />} />
              </Route>
              <Route path="/admin/dashboard" element={<AdminLayout />}>
                <Route index element={<AdminDashboard />} />
              </Route>
              <Route path="/admin/accounts" element={<AdminLayout />}>
                <Route index element={<AccountList />} />
              </Route>
              <Route path="/admin/account/new" element={<AdminLayout />}>
                <Route index element={<AccountDetail />} />
              </Route>
              <Route
                path="/admin/accounts/:accountUuid"
                element={<AdminLayout />}
              >
                <Route index element={<AccountDetail />} />
              </Route>
              <Route path="/admin/phishees" element={<AdminLayout />}>
                <Route index element={<PhisheeList />} />
              </Route>
              <Route path="/admin/phishees/new" element={<AdminLayout />}>
                <Route index element={<PhisheeDetail />} />
              </Route>
              <Route
                path="/admin/phishees/:phisheeUuid"
                element={<AdminLayout />}
              >
                <Route index element={<PhisheeDetail />} />
              </Route>
              <Route path="/admin/emails" element={<AdminLayout />}>
                <Route index element={<EmailList />} />
              </Route>
              <Route path="/admin/emails/:emailUuid" element={<AdminLayout />}>
                <Route index element={<EmailDetail />} />
              </Route>
              <Route
                path="/admin/tier-1-template-generator"
                element={<AdminLayout />}
              >
                <Route index element={<Tier1TemplateGenerator />} />
              </Route>
              <Route
                path="/admin/tier-2-template-generator"
                element={<AdminLayout />}
              >
                <Route index element={<Tier2TemplateGenerator />} />
              </Route>
              <Route path="/admin/email-templates" element={<AdminLayout />}>
                <Route index element={<EmailTemplateList />} />
              </Route>
              <Route
                path="/admin/email-templates/:emailTemplateUuid"
                element={<AdminLayout />}
              >
                <Route index element={<EmailTemplateDetail />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </LoadingProvider>
    </CookiesProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as Container);
root.render(<App />);
