import React from "react";
import { EmailTemplate } from "api/types";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { deleteEmailTemplate } from "api/admin/template";
import { convertUTCToLocalTime } from "utils";

interface TemplateHistoryProps {
  history: EmailTemplate[];
  selectedTemplateId: number;
  fetchEmailTemplate: () => void;
}

const TemplateHistory = (props: TemplateHistoryProps) => {
  const navigate = useNavigate();

  const SelectButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/admin/email-templates/${params.row.emailTemplateUuid}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
        disabled={params.id === props.selectedTemplateId}
      >
        {params.id === props.selectedTemplateId ? "Selected" : "Select"}
      </Button>
    );
  };

  const DeleteButton = (params: any) => {
    const handleOnClick = (e: any) => {
      deleteEmailTemplate(params.row.emailTemplateUuid);
      props.fetchEmailTemplate();
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Delete
      </Button>
    );
  };

  const columns: GridColDef[] = [
    { field: "emailTemplateId", headerName: "ID", width: 70 },
    {
      field: "updatedDate",
      headerName: "Date",
      width: 200,
      type: "date",
      valueGetter: (_params, row) =>
        new Date(convertUTCToLocalTime(row.updatedDate) as string),
    },
    {
      field: "emailSubject",
      headerName: "Subject",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "Select",
      headerName: "",
      width: 150,
      renderCell: SelectButton,
    },
    {
      field: "Delete",
      headerName: "",
      width: 150,
      renderCell: DeleteButton,
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={props.history}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50]}
        getRowId={(row) => row.emailTemplateId}
      />
    </Box>
  );
};

export default TemplateHistory;
