import { useState } from "react";
import {
  ButtonBase,
  Grid,
  Box,
  Typography,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import PhishUpIcon from "components/Icon/PhishUpIcon";
import { color, contentWidth } from "style";
import { useCookies } from "react-cookie";
import { COOKIES } from "const";

const Header = () => {
  // eslint-disable-next-line
  const [cookie, _, removeCookie] = useCookies([
    COOKIES.PHISHEE_FIRST_NAME,
    COOKIES.PHISHEE_LAST_NAME,
    COOKIES.PHISHEE_UUID,
    COOKIES.PHISHEE_ACCESS_TOKEN,
    COOKIES.ACCOUNT_UUID,
  ]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl === null ? handleOpen(event) : handleClose();
  };
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeCookie(COOKIES.PHISHEE_FIRST_NAME);
    removeCookie(COOKIES.PHISHEE_LAST_NAME);
    removeCookie(COOKIES.PHISHEE_UUID);
    removeCookie(COOKIES.PHISHEE_ACCESS_TOKEN);
    removeCookie(COOKIES.ACCOUNT_UUID);
  };

  const menuHeight = {
    desktop: "40px",
    laptop: "40px",
    tablet: "36px",
    mobile: "32px",
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        backgroundColor: color.textInteractivePrimaryHover,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: {
          mobile: "48px",
          tablet: "56px",
          laptop: "64px",
        },
      }}
    >
      <Grid
        container
        item
        sx={{
          maxWidth: {
            ...contentWidth,
          },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: {
            ...menuHeight,
          },
        }}
      >
        <Box sx={{}}>
          <PhishUpIcon
            type="secondary"
            link="/dashboard"
            sx={{
              width: {
                mobile: "74px",
                tablet: "85px",
                laptop: "85px",
                desktop: "85px",
              },
              height: {
                mobile: "28px",
                tablet: "32px",
                laptop: "32px",
                desktop: "32px",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignContent: "center",
          }}
        >
          <Typography
            variant="md"
            sx={{
              color: color.white,
              display: "flex",
              alignItems: "center",
              mr: "16px",
            }}
          >
            {cookie.firstName} {cookie.lastName}
          </Typography>
          <ButtonBase onClick={handleProfileMenuClick}>
            <Avatar>
              {cookie.phisheeFirstName[0].toUpperCase()}
              {cookie.phisheeLastName[0].toUpperCase()}
            </Avatar>
            {anchorEl === null ? (
              <KeyboardArrowDown sx={{ color: color.white, ml: "8px" }} />
            ) : (
              <KeyboardArrowUp sx={{ color: color.white, ml: "8px" }} />
            )}
          </ButtonBase>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              },
            }}
          >
            <MenuItem key={"logout"} onClick={handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Header;
