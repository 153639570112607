import { ValidationError } from "api/types";
import axios, { AxiosResponse } from "axios";

import { PhisheeDashboard } from "api/types";
import { phisheeConfig } from "api/const";

export const getPhisheeDashboard = async (
  phisheeUuid: string
): Promise<AxiosResponse<PhisheeDashboard>> => {
  const url = `/api/v1/phishee/${phisheeUuid}/dashboard`;
  let res = null;
  try {
    res = await axios.get(url, phisheeConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};
