import { ValidationError } from "api/types";
import axios, { AxiosResponse } from "axios";

interface EmailClickResponse {
  phisheeUuid: string;
  token: string;
  accountUuid: string;
  firstName: string;
  lastName: string;
}
export const postEmailClick = async (
  emailId: string
): Promise<AxiosResponse<EmailClickResponse>> => {
  const url = `/api/v1/email/${emailId}/click`;
  let res = null;
  try {
    res = await axios.post(url, {});

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const postEmailReport = async (
  emailId: string
): Promise<AxiosResponse<EmailClickResponse>> => {
  const url = `/api/v1/email/${emailId}/report`;
  let res = null;
  try {
    res = await axios.post(url);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};
