import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAccountInvite as apiGetAccountInvite } from "api/auth";
import { Account } from "api/types";

const Invite = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const { inviteId } = useParams();

  const getAccountInvite = async () => {
    if (!inviteId) {
      // redirect to error page
      return;
    }
    const res = await apiGetAccountInvite(inviteId);
    if (res.status === 200) {
      setAccount(res.data);
    } else {
      alert("Error getting account invite");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAccountInvite();
    setIsLoading(false);
  }, [inviteId]);

  if (isLoading || !account) {
    return (
      <>
        <div>Loading...</div>
      </>
    );
  }

  return (
    <div>
      <h1>Your account has been activated. Please go login</h1>
      <div id="account-uuid" style={{ display: "none" }}>
        {account.accountUuid}
      </div>
    </div>
  );
};

export default Invite;
