import React from "react";
import { Card as MuiCard, CardHeader, CardContent } from "@mui/material";
import { color, spacings } from "style";

interface CardProps {
  children: React.ReactNode;
  name: string;
}

const Card = ({ children, name }: CardProps) => {
  return (
    <MuiCard
      sx={{
        width: "100%",
        borderRadius: "12px",
        border: `1px solid ${color.grey50}`,
      }}
    >
      <CardHeader
        title={name}
        sx={{
          p: spacings.spacing24,
          borderBottom: `1px solid ${color.grey50}`,
        }}
      />
      <CardContent sx={{ p: spacings.spacing24 }}>{children}</CardContent>
    </MuiCard>
  );
};

export default Card;
