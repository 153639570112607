import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import { Collapse } from "@mui/material";
import { Cookies, useCookies } from "react-cookie";
import { onLogout } from "api/auth";
import {
  ContactMail,
  Send,
  Logout,
  AccountBox,
  Settings,
  Filter1,
  Filter2,
  Email,
  Dashboard,
} from "@mui/icons-material";
import { COOKIES } from "const";
import PhishUpIcon from "components/Icon/PhishUpIcon";
import { adminConfig } from "api/const";
import { useLoading } from "components/LoadingProvider";

const AdminLayout = () => {
  const [cookies, , removeCookie] = useCookies([COOKIES.ADMIN_ACCESS_TOKEN]);
  const navigate = useNavigate();
  const adminAccessToken = cookies[COOKIES.ADMIN_ACCESS_TOKEN];
  const { setIsLoading } = useLoading();

  if (!adminAccessToken) {
    return <Navigate to="/admin" replace={true} />;
  }

  const handleLogout = async () => {
    setIsLoading(true);
    await onLogout(adminConfig);
    removeCookie(COOKIES.ADMIN_ACCESS_TOKEN, { path: "/" });
    navigate("/admin");
    setIsLoading(false);
  };

  const drawerWidth = 250;

  return (
    <>
      <Container
        component="main"
        sx={{ p: { xs: 0 }, maxWidth: { md: "none" } }}
        id="upphish-container"
      >
        <Box sx={{ display: "flex" }}>
          <Drawer
            anchor="left"
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <Box role="presentation">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PhishUpIcon
                  link="/account/dashboard"
                  width="200px"
                  height="100px"
                />
              </Box>
              <List>
                <ListItem key="Dashboard" disablePadding>
                  <ListItemButton component={Link} to={"/admin/dashboard"}>
                    <ListItemIcon>
                      <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Accounts" disablePadding>
                  <ListItemButton component={Link} to={"/admin/accounts"}>
                    <ListItemIcon>
                      <AccountBox />
                    </ListItemIcon>
                    <ListItemText primary="Accounts" />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Phishees" disablePadding>
                  <ListItemButton component={Link} to={"/admin/phishees"}>
                    <ListItemIcon>
                      <ContactMail />
                    </ListItemIcon>
                    <ListItemText primary="Phishees" />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Emails" disablePadding>
                  <ListItemButton component={Link} to={"/admin/emails"}>
                    <ListItemIcon>
                      <Send />
                    </ListItemIcon>
                    <ListItemText primary="Emails" />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Email Templates" disablePadding>
                  <ListItemButton
                    component={Link}
                    to={"/admin/email-templates"}
                  >
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    <ListItemText primary="Email Templates" />
                  </ListItemButton>
                </ListItem>
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/admin/email-templates?tier=1"
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <Filter1 />
                      </ListItemIcon>
                      <ListItemText primary="Tier 1" />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to="/admin/email-templates?tier=2"
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <Filter2 />
                      </ListItemIcon>
                      <ListItemText primary="Tier 2" />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItem key="Template Generator" disablePadding>
                  <ListItemButton component="div">
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Template Generator" />
                  </ListItemButton>
                </ListItem>
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/admin/tier-1-template-generator"
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <Filter1 />
                      </ListItemIcon>
                      <ListItemText primary="Tier 1" />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to="/admin/tier-2-template-generator"
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <Filter2 />
                      </ListItemIcon>
                      <ListItemText primary="Tier 2" />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItem key="logout" disablePadding>
                  <ListItemButton onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              overflow: "auto",
              width: "calc(100% - 250px)",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AdminLayout;
