import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, Link, useNavigate, Navigate } from "react-router-dom";
import { Container } from "@mui/system";
import { useCookies } from "react-cookie";
import { onLogout } from "api/auth";
import {
  Logout,
  AccountBox,
  Settings,
  Dashboard,
  GppMaybe,
  Campaign,
  Feedback,
} from "@mui/icons-material";
import { COOKIES } from "const";
import PhishUpIcon from "components/Icon/PhishUpIcon";
import { useLoading } from "components/LoadingProvider";

const AdminLayout = () => {
  const [cookies, , removeCookie] = useCookies([
    COOKIES.ACCOUNT_ACCESS_TOKEN,
    COOKIES.ACCOUNT_EMAIL,
    COOKIES.ACCOUNT_UUID,
    COOKIES.ACCOUNT_FIRST_NAME,
    COOKIES.ACCOUNT_LAST_NAME,
  ]);
  const { setIsLoading } = useLoading();

  const accountAccessToken = cookies[COOKIES.ACCOUNT_ACCESS_TOKEN];
  const accountEmaill = cookies[COOKIES.ACCOUNT_EMAIL];
  const navigate = useNavigate();

  if (!accountAccessToken) {
    return <Navigate to="/login" replace={true} />;
  }

  const handleLogout = () => {
    setIsLoading(true);
    onLogout();
    removeCookie(COOKIES.ACCOUNT_ACCESS_TOKEN, { path: "/" });
    removeCookie(COOKIES.ACCOUNT_UUID, { path: "/" });
    removeCookie(COOKIES.ACCOUNT_FIRST_NAME, { path: "/" });
    removeCookie(COOKIES.ACCOUNT_LAST_NAME, { path: "/" });
    removeCookie(COOKIES.ACCOUNT_EMAIL, { path: "/" });
    setIsLoading(false);
    navigate("/login");
  };

  const handleSendFeedback = () => {
    window.open(
      `https://docs.google.com/forms/d/e/1FAIpQLSdbb3JEmp1wXlDVli1MQTpLIo4bIYuz7vvBch6DQUQabN6iiA/viewform?usp=pp_url&entry.11589743=${accountEmaill}`,
      "_blank"
    );
  };

  const drawerWidth = 250;

  return (
    <>
      <Container
        component="main"
        sx={{ height: "100%" }}
        id="upphish-container"
      >
        <Box sx={{ display: "flex", height: "100%" }}>
          <Drawer
            anchor="left"
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <Box role="presentation" sx={{ height: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PhishUpIcon
                  link="/account/dashboard"
                  width="200px"
                  height="100px"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "calc(100% - 100px)",
                }}
              >
                <List>
                  <ListItem key="Dashboard" disablePadding>
                    <ListItemButton component={Link} to={"/account/dashboard"}>
                      <ListItemIcon>
                        <Dashboard />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="User Management" disablePadding>
                    <ListItemButton component={Link} to={"/account/users"}>
                      <ListItemIcon>
                        <AccountBox />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="Phish Attempts" disablePadding>
                    <ListItemButton component={Link} to={"/account/emails"}>
                      <ListItemIcon>
                        <GppMaybe />
                      </ListItemIcon>
                      <ListItemText primary="Phish Attempts" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="Settings" disablePadding>
                    <ListItemButton component={Link} to={"/account/settings"}>
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="Campaign" disablePadding>
                    <ListItemButton component={Link} to={"/account/campaigns"}>
                      <ListItemIcon>
                        <Campaign />
                      </ListItemIcon>
                      <ListItemText primary="Campaign" />
                    </ListItemButton>
                  </ListItem>
                </List>
                <List>
                  <ListItem key="feedback" disablePadding>
                    <ListItemButton onClick={handleSendFeedback}>
                      <ListItemIcon>
                        <Feedback />
                      </ListItemIcon>
                      <ListItemText primary="Send Feedback" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="logout" disablePadding>
                    <ListItemButton onClick={handleLogout}>
                      <ListItemIcon>
                        <Logout />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Drawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              overflow: "auto",
              width: "calc(100% - 250px)",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AdminLayout;
