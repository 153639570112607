import { Typography } from "@mui/material";

const AccountSettings = () => {
  return (
    <div>
      <Typography variant="h1" gutterBottom>
        Settings - TBD
      </Typography>
    </div>
  );
};

export default AccountSettings;
