import { styled } from "@mui/material/styles";
import { PieChart as MuiPieChart } from "@mui/x-charts/PieChart";
import Card from "components/Phishee/Card";
import { color, fontFamily } from "style";
import { useDrawingArea } from "@mui/x-charts/hooks";

interface PieChartProps {
  reported: number;
  clicked: number;
  missed: number;
}
const PieChart = ({ reported, clicked, missed }: PieChartProps) => {
  const calculateOverallProgress = () => {
    const total = reported + clicked + missed;
    return Math.round((reported / total) * 100);
  };

  return (
    <Card name="Overall progress">
      <MuiPieChart
        series={[
          {
            innerRadius: 30,
            data: [
              {
                id: 0,
                value: reported,
                label: "Reported",
                color: color.blue500,
              },
              {
                id: 1,
                value: clicked,
                label: "Clicked",
                color: "#FF7875",
              },
              {
                id: 2,
                value: missed,
                label: "Missed",
                color: color.blue200,
              },
            ],
          },
        ]}
        width={258}
        height={102}
        tooltip={{ trigger: "none" }}
      >
        <PieCenterLabel>{calculateOverallProgress()}%</PieCenterLabel>
      </MuiPieChart>
    </Card>
  );
};

const StyledText = styled("text")(({ theme }) => ({
  fill: color.blue600,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: "18px",
  fontWeight: "500",
  fontFamily: fontFamily.primary,
}));

const PieCenterLabel = ({ children }: { children: React.ReactNode }) => {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
};

export default PieChart;
