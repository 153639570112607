import Header from "components/Phishee/Header";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import { color, contentWidth } from "style";
import { useCookies } from "react-cookie";
import { COOKIES } from "const";

const PhisheeLayout = () => {
  const [cookie] = useCookies([COOKIES.PHISHEE_ACCESS_TOKEN]);

  if (cookie.phisheeAccessToken === undefined) {
    return "not allowed";
  }

  return (
    <Grid container sx={{ backgroundColor: color.brand10, height: "100vh" }}>
      <Header />
      <Grid
        container
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "top",
          height: "100%",
        }}
      >
        <Grid
          sx={{
            maxWidth: { ...contentWidth },
            width: "100%",
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PhisheeLayout;
