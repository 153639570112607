import { COOKIES } from "const";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";

const AdminHome = () => {
  const [cookie] = useCookies([COOKIES.ADMIN_ACCESS_TOKEN]);

  return (
    <>
      {cookie.adminAccessToken ? (
        <Navigate to="/admin/dashboard" replace={true} />
      ) : (
        <Navigate to="/admin/login" replace={true} />
      )}
    </>
  );
};

export default AdminHome;
