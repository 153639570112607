export const objectToQueryParams = (obj: { [key: string]: any }): string =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");

export const convertUTCToLocalTime = (utc: Date | string | undefined) => {
  if (utc === undefined) {
    return "";
  }

  // Create a new Date object from the UTC date string
  if (typeof utc == "string") {
    utc = new Date(utc);
  }

  // Check if the date is valid
  if (isNaN(utc.getTime())) {
    console.error("Invalid UTC date string");
    return null;
  }

  // Convert the UTC date to a localized string in the browser's timezone
  // This automatically adjusts to the user's local timezone
  const localDateString = utc.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });

  return localDateString;
};
