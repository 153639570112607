import { Box, Button, Container, Typography } from "@mui/material";
import Card from "components/Phishee/Card";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { PieChart } from "@mui/x-charts/PieChart";
import { color, fontFamily } from "style";
import { useLoading } from "components/LoadingProvider";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { COOKIES } from "const";
import { getAccountDashboard } from "api/account/dashboard";
import {
  AccountDashboardPhisheesInDanger,
  AccountDashboard as AccountDashboardType,
} from "api/types";
import { useNavigate } from "react-router-dom";

const AccountDashboard = () => {
  const [dashboard, setDashboard] = useState<AccountDashboardType | undefined>(
    undefined
  );
  const [cookie] = useCookies([COOKIES.ACCOUNT_UUID]);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const fetchDashboard = useCallback(async () => {
    if (dashboard) {
      return;
    }
    setIsLoading(true);

    const res = await getAccountDashboard(cookie.accountUuid);
    if (res.status === 200) {
      setDashboard(res.data);
    }
    setIsLoading(false);
  }, [setIsLoading, cookie.accountUuid, dashboard]);

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  const calculateOverallHealth = () => {
    if (!dashboard?.overallHealth) {
      return 0;
    }
    const total =
      dashboard.overallHealth.reported +
      dashboard.overallHealth.clicked +
      dashboard.overallHealth.missed;
    return Math.round(
      ((total - dashboard.overallHealth.reported) / total) * 100
    );
  };

  if (!dashboard) {
    return <></>;
  }

  const ViewButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/account/users/${params.id}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        View
      </Button>
    );
  };

  const columns: GridColDef<AccountDashboardPhisheesInDanger>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (_, row) => `${row.user.firstName} ${row.user.lastName}`,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      valueGetter: (_, row) => row.user.email,
    },
    {
      field: "clicked",
      headerName: "Clicked Emails",
      width: 150,
      valueGetter: (_, row) => row.clickedEmails,
    },
    {
      field: "View",
      headerName: "",
      width: 120,
      renderCell: ViewButton,
    },
  ];

  return (
    <>
      <Container>
        <Typography variant="h1" gutterBottom>
          Dashboard
        </Typography>
        <Box>
          <Box>
            <Card name="Overall Health">
              <Box sx={{ display: "flex", gap: 8 }}>
                <Box>
                  <PieChart
                    series={[
                      {
                        innerRadius: 30,
                        data: [
                          {
                            id: 0,
                            value: dashboard.overallHealth?.reported || 0,
                            label: "Reported",
                            color: color.blue500,
                          },
                          {
                            id: 1,
                            value: dashboard.overallHealth?.clicked || 0,
                            label: "Clicked",
                            color: "#FF7875",
                          },
                          {
                            id: 2,
                            value: dashboard.overallHealth?.missed || 0,
                            label: "Missed",
                            color: color.blue200,
                          },
                        ],
                      },
                    ]}
                    width={258}
                    height={102}
                    tooltip={{ trigger: "none" }}
                  >
                    <PieCenterLabel>{calculateOverallHealth()}%</PieCenterLabel>
                  </PieChart>
                </Box>
                <Box
                  sx={{
                    // height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "102px",
                  }}
                >
                  <Typography variant="body1">
                    Total Attempts:{" "}
                    {dashboard.overallHealth.clicked +
                      dashboard.overallHealth.reported +
                      dashboard.overallHealth.missed}
                  </Typography>
                  <Typography variant="body1">
                    Clicked Emails: {dashboard?.overallHealth?.clicked}
                  </Typography>
                  <Typography variant="body1">
                    Reported Emails: {dashboard?.overallHealth?.reported}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Card name="Users In Danger">
              <Box>
                <DataGrid
                  rows={dashboard.phisheesInDanger}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 25 },
                    },
                  }}
                  pageSizeOptions={[25, 50]}
                  getRowId={(row) => row.phisheeUuid}
                />
              </Box>
            </Card>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AccountDashboard;

const StyledText = styled("text")(({ theme }) => ({
  fill: color.blue600,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: "18px",
  fontWeight: "500",
  fontFamily: fontFamily.primary,
}));

const PieCenterLabel = ({ children }: { children: React.ReactNode }) => {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
};
