import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "components/LoadingProvider";
import { Box } from "@mui/system";
import * as api from "api/admin/account";
import { Account, AccountListFilter } from "api/types";
import { convertUTCToLocalTime, objectToQueryParams } from "utils";

const initialFilter = {
  isActive: "all",
} as AccountListFilter;

const AccountList = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [filter, setFilter] = useState(initialFilter);
  const [searchParams] = useSearchParams();
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const fetchEmailTemplates = useCallback(
    async (newFilter?: AccountListFilter) => {
      setIsLoading(true);
      const res = await api.getAccounts(newFilter);
      if (res.status === 200) {
        setAccounts(res.data);
      }
      setIsLoading(false);
    },
    [setIsLoading]
  );

  useEffect(() => {
    var newFilter = {
      isActive: searchParams.get("isActive") ?? "all",
    };
    fetchEmailTemplates(newFilter);
  }, [searchParams, fetchEmailTemplates]);

  const handleSearch = (_e: any) => {
    const queryParam = objectToQueryParams(filter);
    navigate(`/admin/accounts?${queryParam}`);
  };

  const DetailButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/admin/accounts/${params.id}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Edit
      </Button>
    );
  };

  const columns: GridColDef<Account>[] = [
    { field: "accountId", headerName: "ID", width: 70 },
    { field: "accountName", headerName: "Accout Name", width: 200 },
    {
      field: "createdDate",
      headerName: "Created",
      width: 150,
      valueGetter: (_params, row) => convertUTCToLocalTime(row.createdDate),
    },
    { field: "plan", headerName: "Plan", width: 150 },
    {
      field: "isActive",
      type: "boolean",
      headerName: "Active",
      width: 150,
      valueGetter: (_value, row) => row.isActive,
    },
    {
      field: "cntPhishee",
      headerName: "# Phishees",
      width: 80,
      valueGetter: (_value, row) => row?.phishees?.length || 0,
    },
    {
      field: "Edit",
      headerName: "",
      width: 150,
      renderCell: DetailButton,
    },
  ];

  return (
    <>
      <Grid container>
        <Box>
          <Typography variant="h5" gutterBottom>
            Account List
          </Typography>
        </Box>
        <Grid container item xs={12}>
          <FormControl sx={{ width: "120px" }}>
            <InputLabel id="demo-simple-select-label">Active</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter.isActive}
              label="Is Active"
              onChange={(e) =>
                setFilter({ ...filter, isActive: e.target.value })
              }
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"true"}>Active</MenuItem>
              <MenuItem value={"false"}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 16 }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ paddingBottom: "1rem" }}>
            {/* <Button
              component={Link}
              to="/admin/account/new"
              variant="contained"
              color="primary"
            >
              Create
            </Button> */}
          </Box>
          <Box>
            <DataGrid
              rows={accounts}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50]}
              getRowId={(row) => row.accountUuid}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountList;
