import axios, { AxiosResponse } from "axios";
import {
  ValidationError,
  Phishee,
  PhisheeListFilter,
  GetAccountPhishee,
} from "api/types";
import { accountConfig } from "api/const";

export const getPhishees = async (
  accountUuid: string,
  filter?: PhisheeListFilter
): Promise<AxiosResponse<Phishee[]>> => {
  const url = `/api/v1/account/${accountUuid}/phishee`;
  let res = null;
  try {
    res = await axios.get(url, {
      ...accountConfig,
      params: filter,
    });

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const getPhishee = async (
  accountUuid: string,
  phisheeUuid: string
): Promise<AxiosResponse<GetAccountPhishee>> => {
  const url = `/api/v1/account/${accountUuid}/phishee/${phisheeUuid}`;
  let res = null;
  try {
    res = await axios.get(url, accountConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const postPhishee = async (accountUuid: string, payload: Phishee) => {
  const url = `/api/v1/account/${accountUuid}/phishee`;
  let res = null;
  try {
    const config = {
      ...accountConfig,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    res = await axios.post(url, payload, config);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const putPhishee = async (
  accountUuid: string,
  phisheeUuid: string,
  payload: Phishee
) => {
  const url = `/api/v1/account/${accountUuid}/phishee/${phisheeUuid}`;
  let res = null;
  try {
    const config = {
      ...accountConfig,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    res = await axios.put(url, payload, config);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const deletePhishee = async (
  accountUuid: string,
  phisheeUuid: string
) => {
  const url = `/api/v1/account/${accountUuid}/phishee/${phisheeUuid}`;
  let res = null;
  try {
    res = await axios.delete(url, accountConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};
