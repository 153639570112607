import axios from "axios";
import { Cookies } from "react-cookie";

axios.interceptors.request.use((config: any) => {
  const cookies = new Cookies();

  config.validateStatus = (status: number) => status < 500;

  config.baseURL = process.env.REACT_APP_CORE_API_HOST;
  const accountAccessToken = cookies.get("accountAccessToken");
  const adminAccessToken = cookies.get("adminAccessToken");
  const phisheeAccessToken = cookies.get("phisheeAccessToken");

  switch (config.accessType) {
    case "admin":
      config.headers["Authorization"] = `Bearer ${adminAccessToken}`;
      break;
    case "phishee":
      config.headers["Authorization"] = `Bearer ${phisheeAccessToken}`;
      break;
    case "account":
      config.headers["Authorization"] = `Bearer ${accountAccessToken}`;
      break;
    default:
      break;
  }
  return config;
});
