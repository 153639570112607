import { Typography } from "@mui/material";

const AccountCampaigns = () => {
  return (
    <div>
      <Typography variant="h1" gutterBottom>
        Campaigns - TBD
      </Typography>
    </div>
  );
};

export default AccountCampaigns;
