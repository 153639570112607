import { AxiosRequestConfig } from "axios";

type AccessType = "admin" | "phishee" | "account";
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  accessType: AccessType;
}

export const phisheeConfig = {
  accessType: "phishee",
} as CustomAxiosRequestConfig;

export const adminConfig = {
  accessType: "admin",
} as CustomAxiosRequestConfig;

export const accountConfig = {
  accessType: "account",
} as CustomAxiosRequestConfig;
