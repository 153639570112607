import { useCallback, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { postEmailClick as apiPostEmailClick } from "api/phishee/email";
import { useCookies } from "react-cookie";
import { COOKIES } from "const";

const EmailClick = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { emailId } = useParams();
  const [, setCookie, removeCookie] = useCookies([
    COOKIES.PHISHEE_ACCESS_TOKEN,
    COOKIES.PHISHEE_UUID,
    COOKIES.ACCOUNT_UUID,
    COOKIES.PHISHEE_FIRST_NAME,
    COOKIES.PHISHEE_LAST_NAME,
  ]);

  const postEmailClick = useCallback(async () => {
    if (!emailId) {
      // redirect to error page
      return;
    }
    const res = await apiPostEmailClick(emailId);
    removeCookie(COOKIES.PHISHEE_FIRST_NAME);
    removeCookie(COOKIES.PHISHEE_LAST_NAME);
    removeCookie(COOKIES.PHISHEE_UUID);
    removeCookie(COOKIES.PHISHEE_ACCESS_TOKEN);
    removeCookie(COOKIES.ACCOUNT_UUID);
    setCookie(COOKIES.PHISHEE_ACCESS_TOKEN, res.data.token, { path: "/" });
    setCookie(COOKIES.PHISHEE_UUID, res.data.phisheeUuid, { path: "/" });
    setCookie(COOKIES.ACCOUNT_UUID, res.data.accountUuid, { path: "/" });
    setCookie(COOKIES.PHISHEE_FIRST_NAME, res.data.firstName, { path: "/" });
    setCookie(COOKIES.PHISHEE_LAST_NAME, res.data.lastName, { path: "/" });

    return res.status === 200;
  }, [emailId, removeCookie, setCookie]);

  useEffect(() => {
    setIsLoading(true);
    postEmailClick();
    setIsLoading(false);
  }, [emailId, postEmailClick]);

  if (isLoading) {
    return (
      <>
        <div>Loading...</div>
      </>
    );
  }

  return (
    <>
      <Navigate to="/dashboard" replace={true} />
    </>
  );
};

export default EmailClick;
