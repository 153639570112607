import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Save, Delete, Send } from "@mui/icons-material";
import { useLoading } from "components/LoadingProvider";
import { Phishee } from "api/types";
import * as api from "api/admin/phishee";
import { postEmail } from "api/admin/email";
import { JOB_FUNCTIONS } from "const";
import { convertUTCToLocalTime } from "utils";

const PhisheeDetail = () => {
  const { phisheeUuid } = useParams();
  const [phishee, setPhishee] = useState<Phishee | undefined>(undefined);
  const [emailTemplateUuid, setEmailTemplateUuid] = useState<string>("");
  const { setIsLoading } = useLoading();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPhishee = async () => {
      if (!phisheeUuid) {
        return;
      }
      setIsLoading(true);

      const res = await api.getPhishee(phisheeUuid);
      if (res.status === 200) {
        setPhishee(res.data);
      }
      setIsLoading(false);
    };
    if (searchParams.get("account")) {
      setPhishee({
        account: {
          accountUuid: searchParams.get("account") as string,
        },
        isActive: undefined,
      });
    }

    fetchPhishee();
  }, [phisheeUuid, searchParams, setIsLoading]);

  const handleSave = async (_event: React.FormEvent<HTMLButtonElement>) => {
    if (!phishee) {
      console.log("No account", phishee);
      return;
    }

    const payload = {
      accountUuid: phishee?.account?.accountUuid,
      email: phishee.user?.email,
      firstName: phishee.user?.firstName,
      lastName: phishee.user?.lastName,
      jobFunction: phishee.jobFunction,
      isActive: phishee.isActive,
    };

    let response = null;
    if (phisheeUuid) {
      response = await api.putPhishee(phisheeUuid, payload);
    } else {
      response = await api.postPhishee(payload);
    }

    if ([200].includes(response?.status)) {
      return navigate(-1);
    } else if ([201].includes(response?.status)) {
      return navigate("/admin/phishees/" + response.data.phisheeUuid);
    }
  };

  const handleSend = async (_event: React.FormEvent<HTMLButtonElement>) => {
    if (phisheeUuid && emailTemplateUuid) {
      const payload = {
        emailTemplate: emailTemplateUuid,
        phishee: phisheeUuid,
      };
      const response = await postEmail(payload);
      if (response.status === 201) {
        navigate("/admin/emails/" + response.data.emailUuid);
        console.log("Email sent");
      }
    }
  };

  if (!phishee && phisheeUuid) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5" gutterBottom>
              Phishee Detail
            </Typography>
          </Box>
          <Box>
            <TextField
              margin="normal"
              label="Account Name"
              variant="outlined"
              fullWidth
              value={phishee?.account?.accountUuid}
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  account: {
                    ...phishee?.account,
                    accountUuid: e.target.value,
                  },
                })
              }
              disabled
            />
            <TextField
              margin="normal"
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              value={phishee?.user?.email}
              disabled={!!phisheeUuid}
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  user: { ...phishee?.user, email: e.target.value },
                })
              }
            />
            <TextField
              margin="normal"
              label="First Name"
              variant="outlined"
              fullWidth
              value={phishee?.user?.firstName}
              name="first-name"
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  user: { ...phishee?.user, firstName: e.target.value },
                })
              }
            />
            <TextField
              margin="normal"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={phishee?.user?.lastName}
              name="last-name"
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  user: { ...phishee?.user, lastName: e.target.value },
                })
              }
            />
            <TextField
              margin="normal"
              label="Created Date"
              variant="outlined"
              fullWidth
              value={convertUTCToLocalTime(phishee?.createdDate)}
              disabled
            />
            <Box>
              <FormControlLabel
                value="jobFunction"
                control={
                  <Select
                    id="job-function-select"
                    value={phishee?.jobFunction}
                    label="Job Function"
                    name="job-function"
                    onChange={(e) =>
                      setPhishee({
                        ...phishee,
                        jobFunction: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={undefined}>Select</MenuItem>
                    {JOB_FUNCTIONS.map((jobFunction) => (
                      <MenuItem key={jobFunction} value={jobFunction}>
                        {jobFunction}
                      </MenuItem>
                    ))}
                  </Select>
                }
                label="Job Function"
                labelPlacement="start"
              />
            </Box>
            <Box>
              <FormControlLabel
                value="isActive"
                control={
                  <Checkbox
                    checked={phishee?.isActive && phishee?.user?.isActive}
                    name="is-active"
                    onChange={(e) =>
                      setPhishee({
                        ...phishee,
                        isActive: e.target.checked,
                      })
                    }
                  />
                }
                label="Is Active"
                labelPlacement="start"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            id="save-phishee"
            variant="contained"
            onClick={handleSave}
            startIcon={<Save />}
          >
            Save
          </Button>
          {phishee?.account?.accountUuid && (
            <Button
              id="open-account"
              variant="contained"
              onClick={() =>
                navigate(`/admin/accounts/${phishee?.account?.accountUuid}`)
              }
              sx={{ ml: 3 }}
              startIcon={<Delete />}
            >
              Open Account
            </Button>
          )}
        </Grid>
        <Grid sx={{ mt: 3 }}>
          {phishee?.isActive && phishee?.user?.isActive && (
            <>
              <TextField
                margin="normal"
                label="Email Template UUID"
                variant="outlined"
                value={emailTemplateUuid}
                onChange={(e) => setEmailTemplateUuid(e.target.value)}
                sx={{ mx: 2, my: 0, width: 350 }}
                name="email-template-uuid"
              />
              <Button
                variant="contained"
                onClick={handleSend}
                startIcon={<Send />}
                id="send-email"
              >
                Send Email
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PhisheeDetail;
