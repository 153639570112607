import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { Save, Delete } from "@mui/icons-material";
import { useLoading } from "components/LoadingProvider";
import { GetAccountPhishee, PhisheeActivity } from "api/types";
import * as api from "api/account/phishee";
import { COOKIES, JOB_FUNCTIONS } from "const";
import { convertUTCToLocalTime } from "utils";
import { useCookies } from "react-cookie";
import PieChart from "components/Phishee/PieChart";
import Card from "components/Phishee/Card";

const AccountUserDetail = () => {
  const { userId } = useParams();
  const [phishee, setPhishee] = useState<GetAccountPhishee | undefined>(
    undefined
  );
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();
  const [cookie] = useCookies([COOKIES.ACCOUNT_UUID]);

  useEffect(() => {
    const fetchPhishee = async () => {
      if (!userId) {
        return;
      }
      setIsLoading(true);

      const res = await api.getPhishee(cookie.accountUuid, userId);
      if (res.status === 200) {
        setPhishee(res.data);
      }
      setIsLoading(false);
    };

    fetchPhishee();
  }, [userId, setIsLoading, cookie.accountUuid]);

  const handleSave = async (_event: React.FormEvent<HTMLButtonElement>) => {
    if (!phishee) {
      console.log("No account", phishee);
      return;
    }

    const payload = {
      accountUuid: cookie.accountUuid,
      email: phishee.user?.email,
      firstName: phishee.user?.firstName,
      lastName: phishee.user?.lastName,
      jobFunction: phishee.jobFunction,
      isActive: phishee.isActive,
    };

    let response = null;
    if (userId) {
      response = await api.putPhishee(cookie.accountUuid, userId, payload);
    } else {
      response = await api.postPhishee(cookie.accountUuid, payload);
    }

    if ([200].includes(response?.status)) {
      return navigate(0);
    } else if ([201].includes(response?.status)) {
      return navigate("/account/users/" + response.data.phisheeUuid);
    }
  };

  if (!phishee && userId) {
    return <div>Loading...</div>;
  }
  const DetailButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/account/emails/${params.id}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Open
      </Button>
    );
  };
  const columns: GridColDef<PhisheeActivity>[] = [
    {
      field: "SentDate",
      headerName: "Sent Date",
      width: 120,
      valueGetter: (_param, row) => convertUTCToLocalTime(row?.createdDate),
    },
    {
      field: "EmailSubject",
      headerName: "Email Subject",
      flex: 1,
      valueGetter: (_params, row) => row?.preparedEmailSubject,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 150,
      valueGetter: (_params, row) => row.status,
    },
    {
      field: "Edit",
      headerName: "",
      width: 150,
      renderCell: DetailButton,
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box>
            <Typography variant="h1" gutterBottom>
              User Detail
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box>
            <TextField
              name="account-uuid"
              margin="normal"
              label="Account UUID"
              variant="outlined"
              fullWidth
              value={phishee?.account?.accountUuid}
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  account: {
                    ...phishee?.account,
                    accountUuid: e.target.value,
                  },
                })
              }
              disabled
            />
            <TextField
              margin="normal"
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              value={phishee?.user?.email}
              disabled={!!userId}
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  user: { ...phishee?.user, email: e.target.value },
                })
              }
            />
            <TextField
              margin="normal"
              label="First Name"
              variant="outlined"
              fullWidth
              value={phishee?.user?.firstName}
              name="first-name"
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  user: { ...phishee?.user, firstName: e.target.value },
                })
              }
            />
            <TextField
              margin="normal"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={phishee?.user?.lastName}
              name="last-name"
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  user: { ...phishee?.user, lastName: e.target.value },
                })
              }
            />
            <TextField
              margin="normal"
              label="Created Date"
              variant="outlined"
              fullWidth
              value={convertUTCToLocalTime(phishee?.createdDate)}
              disabled
            />
            <Box>
              <FormControlLabel
                value="jobFunction"
                control={
                  <Select
                    id="job-function-select"
                    value={phishee?.jobFunction}
                    label="Job Function"
                    name="job-function"
                    onChange={(e) =>
                      setPhishee({
                        ...phishee,
                        jobFunction: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={undefined}>Select</MenuItem>
                    {JOB_FUNCTIONS.map((jobFunction) => (
                      <MenuItem key={jobFunction} value={jobFunction}>
                        {jobFunction}
                      </MenuItem>
                    ))}
                  </Select>
                }
                label="Job Function"
                labelPlacement="start"
              />
            </Box>
            <Box>
              <FormControlLabel
                value="isActive"
                control={
                  <Checkbox
                    checked={phishee?.isActive && phishee?.user?.isActive}
                    name="is-active"
                    onChange={(e) =>
                      setPhishee({
                        ...phishee,
                        isActive: e.target.checked,
                      })
                    }
                  />
                }
                label="Is Active"
                labelPlacement="start"
              />
            </Box>
          </Box>
          <Grid item xs={12}>
            <Button
              id="save-phishee"
              variant="contained"
              onClick={handleSave}
              startIcon={<Save />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        {userId && phishee && phishee?.activity && phishee?.overallProgress && (
          <Grid item xs={7}>
            <Box>
              <PieChart
                reported={phishee?.overallProgress?.reported || 0}
                clicked={phishee?.overallProgress?.clicked || 0}
                missed={phishee?.overallProgress?.missed || 0}
              />
            </Box>
            <Box>
              <Card name="Phishing Attempt History">
                <DataGrid
                  rows={phishee?.activity}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 25 },
                    },
                  }}
                  pageSizeOptions={[25, 50]}
                  getRowId={(row) => row.emailUuid ?? ""}
                />
              </Card>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AccountUserDetail;
