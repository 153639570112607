import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { Save, Delete, Refresh } from "@mui/icons-material";

import { GetEmailTemplateDetail } from "api/types";
import {
  deleteEmailTemplate,
  getEmailTemplate,
  postEmailTemplateRefresh,
  putEmailTemplate,
} from "api/admin/template";
import { useLoading } from "components/LoadingProvider";
import TemplateHistory from "components/TemplateHistory";
import { convertUTCToLocalTime } from "utils";

const EmailTemplateDetail = () => {
  const { emailTemplateUuid } = useParams();
  const navigate = useNavigate();
  const [emailTemplate, setEmailTemplate] = useState<
    GetEmailTemplateDetail | undefined
  >(undefined);
  const { setIsLoading } = useLoading();

  const fetchEmailTemplate = useCallback(async () => {
    if (!emailTemplateUuid) {
      return;
    }
    setIsLoading(true);

    const res = await getEmailTemplate(emailTemplateUuid);
    if (res.status === 200) {
      setEmailTemplate(res.data);
    }
    setIsLoading(false);
  }, [setIsLoading, emailTemplateUuid]);

  useEffect(() => {
    // fetch list
    fetchEmailTemplate();
  }, [fetchEmailTemplate]);

  if (!emailTemplate) {
    return <div>Loading...</div>;
  }

  const handleRefresh = async (_event: React.FormEvent<HTMLButtonElement>) => {
    setIsLoading(true);
    const response = await postEmailTemplateRefresh(
      emailTemplate.emailTemplateUuid
    );
    if (response.status === 201) {
      setEmailTemplate(response.data);
    }
    setIsLoading(false);
  };

  const handleDelete = async (_event: React.FormEvent<HTMLButtonElement>) => {
    setIsLoading(true);
    const response = await deleteEmailTemplate(emailTemplate.emailTemplateUuid);
    if (response.status === 204) {
      return navigate(-1);
    }
    setIsLoading(false);
  };

  const handleSave = async (_event: React.FormEvent<HTMLButtonElement>) => {
    const response = await putEmailTemplate(emailTemplate);
    if (response.status === 200) {
      return navigate(-1);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <TextField
              margin="normal"
              label="ID"
              variant="outlined"
              fullWidth
              value={emailTemplate.emailTemplateId}
              disabled
              name="emailTemplateId"
            />
            <TextField
              margin="normal"
              label="UUID"
              variant="outlined"
              fullWidth
              value={emailTemplate.emailTemplateUuid}
              disabled
              name="emailTemplateUuid"
            />
            <TextField
              margin="normal"
              label="Created"
              variant="outlined"
              fullWidth
              value={convertUTCToLocalTime(emailTemplate.createdDate)}
              disabled
            />
            <TextField
              margin="normal"
              label="Updated"
              variant="outlined"
              fullWidth
              value={convertUTCToLocalTime(emailTemplate.updatedDate)}
              disabled
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={emailTemplate.status}
                label="Age"
                onChange={(e) =>
                  setEmailTemplate({ ...emailTemplate, status: e.target.value })
                }
              >
                <MenuItem value={"draft"}>Draft</MenuItem>
                <MenuItem value={"deleted"}>Deleted</MenuItem>
                <MenuItem value={"published"}>Published</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              label="Sender Email"
              variant="outlined"
              fullWidth
              value={emailTemplate.senderEmail}
              onChange={(e) =>
                setEmailTemplate({
                  ...emailTemplate,
                  senderEmail: e.target.value,
                })
              }
              name="senderEmail"
            />
            <TextField
              margin="normal"
              label="Sender Name"
              variant="outlined"
              fullWidth
              value={emailTemplate.emailSenderName}
              onChange={(e) =>
                setEmailTemplate({
                  ...emailTemplate,
                  emailSenderName: e.target.value,
                })
              }
              name="emailSenderName"
            />
            <TextField
              margin="normal"
              label="Subject"
              variant="outlined"
              fullWidth
              value={emailTemplate.emailSubject}
              onChange={(e) =>
                setEmailTemplate({
                  ...emailTemplate,
                  emailSubject: e.target.value,
                })
              }
              name="emailSubject"
            />
            <TextField
              key={`body-${emailTemplate.emailTemplateId}`}
              value={emailTemplate.emailBody}
              onChange={(e: any) => {
                e.preventDefault();
                setEmailTemplate({
                  ...emailTemplate,
                  emailBody: e.target.value,
                });
              }}
              fullWidth
              name="emailBody"
              InputProps={{
                rows: 20,
                multiline: true,
                inputComponent: "textarea",
              }}
            />
            <TextField
              margin="normal"
              label="Provider"
              variant="outlined"
              fullWidth
              value={emailTemplate.provider}
              onChange={(e) =>
                setEmailTemplate({
                  ...emailTemplate,
                  provider: e.target.value,
                })
              }
              name="provider"
            />
            <Button
              id="email-template-save"
              variant="contained"
              onClick={handleSave}
              sx={{ marginRight: 2 }}
              startIcon={<Save />}
            >
              Save
            </Button>
            {emailTemplate.tier === 2 && (
              <Button
                variant="contained"
                startIcon={<Refresh />}
                onClick={handleRefresh}
              >
                Refresh
              </Button>
            )}
            <Button
              variant="outlined"
              startIcon={<Delete />}
              onClick={handleDelete}
              sx={{ marginLeft: 2 }}
            >
              Delete
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          {emailTemplate.history && (
            <TemplateHistory
              history={emailTemplate.history}
              selectedTemplateId={emailTemplate.emailTemplateId}
              fetchEmailTemplate={fetchEmailTemplate}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: emailTemplate.emailBody }} />
        </Grid>
      </Grid>
    </>
  );
};

export default EmailTemplateDetail;
