import axios, { AxiosResponse } from "axios";
import { ValidationError, EmailListFilter, Email } from "api/types";
import { adminConfig } from "api/const";

export const getEmails = async (
  filter?: EmailListFilter
): Promise<AxiosResponse<Email[]>> => {
  const url = `/api/v1/email`;
  let res = null;
  try {
    const payload = {
      ...adminConfig,
      params: filter,
    };
    res = await axios.get(url, payload);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const getEmail = async (
  emailId: string
): Promise<AxiosResponse<Email>> => {
  const url = `/api/v1/email/${emailId}`;
  let res = null;
  try {
    res = await axios.get(url, adminConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export interface SendEmailPayload {
  emailTemplate: string;
  phishee: string;
}
export const postEmail = async (
  payload: SendEmailPayload
): Promise<AxiosResponse<Email>> => {
  const url = `/api/v1/email`;
  let res = null;
  try {
    res = await axios.post(url, payload, adminConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};
