import * as React from "react";
import Box from "@mui/material/Box";
import { Container, Typography, TextField, Button } from "@mui/material";
import styles from "./styles";

const JoinWaitlist = () => {
  const [email, setEmail] = React.useState("");

  return (
    <Container sx={styles.container}>
      <Box sx={styles.box}>
        <Typography variant="h3" sx={styles.h3a} textAlign="center">
          Join the waitlist for the
        </Typography>
        <Typography variant="h3" sx={styles.h3b} textAlign="center">
          UpPhish Add on!
        </Typography>
      </Box>
      <Box sx={styles.box2}>
        <TextField
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          required
          variant="outlined"
          placeholder="Enter your email"
          sx={styles.emailInput}
        />
        <Button variant="contained" sx={styles.submitButton}>
          Join the waitlist
        </Button>
      </Box>
    </Container>
  );
};

export default JoinWaitlist;
