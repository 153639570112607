import { ValidationError } from "api/types";
import axios, { AxiosResponse } from "axios";

import { AccountDashboard } from "api/types";
import { accountConfig } from "api/const";

export const getAccountDashboard = async (
  accountUuid: string
): Promise<AxiosResponse<AccountDashboard>> => {
  const url = `/api/v1/account/${accountUuid}/dashboard`;
  let res = null;
  try {
    res = await axios.get(url, accountConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};
