export const PREMIUM_PLAN = "Premium";
export const STANDARD_PLAN = "Standard";
export const FREE_PLAN = "Free";
export const ACCOUNT_PLANS = [PREMIUM_PLAN, STANDARD_PLAN, FREE_PLAN];

export const EMAIL_STATUS = {
  EMAIL_SENT: "EMAIL_SENT",
  EMAIL_FAILED: "EMAIL_FAILED",
  EMAIL_CLICKED: "EMAIL_CLICKED",
  EMAIL_REPORTED: "EMAIL_REPORTED",
};

export const JOB_FUNCTIONS = [
  "Accounting / Finance",
  "Administrative",
  "Arts / Media / Design",
  "Biotech / Science",
  "Business / Management",
  "Customer Service",
  "Education",
  "Engineering",
  "Government",
  "Healthcare",
  "Human Resources",
  "Information Technology",
  "Legal",
  "Marketing / Advertising / PR",
  "Non-Profit / Volunteer",
  "Real Estate",
  "Restaurant / Food Service",
  "Retail",
  "Sales",
  "Skilled Trade / Craft",
  "Transportation",
  "Other",
];

export const COOKIES = {
  ACCOUNT_UUID: "accountUuid",
  ACCOUNT_ACCESS_TOKEN: "accountAccessToken",
  ACCOUNT_FIRST_NAME: "accountFirstName",
  ACCOUNT_LAST_NAME: "accountLastName",
  ACCOUNT_EMAIL: "accountEmail",
  PHISHEE_ACCESS_TOKEN: "phisheeAccessToken",
  PHISHEE_FIRST_NAME: "phisheeFirstName",
  PHISHEE_LAST_NAME: "phisheeLastName",
  PHISHEE_UUID: "phisheeUuid",
  ADMIN_ACCESS_TOKEN: "adminAccessToken",
};

export const TOKEN_COMPANY_NAME = "RECIPIENT_COMPANY_NAME";
export const TOKEN_RECIPIENT_FULL_NAME = "RECIPIENT_FULL_NAME";
export const TOKEN_RECIPIENT_FIRST_NAME = "RECIPIENT_FIRST_NAME";
export const TOKEN_RECIPIENT_LAST_NAME = "RECIPIENT_LAST_NAME";
export const TOKEN_RECIPIENT_EMAIL = "RECIPIENT_EMAIL";
export const TOKEN_SENDER_NAME = "SENDER_NAME";
export const TOKEN_LINK = "LINK";

export const TEMPLATE_TOKENS = [
  TOKEN_COMPANY_NAME,
  TOKEN_RECIPIENT_FULL_NAME,
  TOKEN_RECIPIENT_FIRST_NAME,
  TOKEN_RECIPIENT_LAST_NAME,
  TOKEN_RECIPIENT_EMAIL,
  TOKEN_LINK,
  TOKEN_SENDER_NAME,
];
