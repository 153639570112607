import { Grid, Box, IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { PieChart } from "@mui/x-charts/PieChart";
import Card from "components/Phishee/Card";
import { color, fontFamily, spacings } from "style";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { useCallback, useEffect, useState } from "react";
import { useLoading } from "components/LoadingProvider";
import { getPhisheeDashboard } from "api/phishee/dashboard";
import { useCookies } from "react-cookie";
import { PhisheeDashboard } from "api/types";
import { COOKIES, EMAIL_STATUS } from "const";

const Dashboard = () => {
  const [dashboard, setDashboard] = useState<PhisheeDashboard | undefined>(
    undefined
  );
  const { setIsLoading } = useLoading();
  const [cookie] = useCookies([COOKIES.PHISHEE_UUID]);

  const fetchDashboard = useCallback(async () => {
    if (dashboard) {
      return;
    }
    setIsLoading(true);

    const res = await getPhisheeDashboard(cookie.phisheeUuid);
    if (res.status === 200) {
      setDashboard(res.data);
    }
    setIsLoading(false);
  }, [setIsLoading, cookie.phisheeUuid, dashboard]);

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  if (dashboard === undefined) {
    return <></>;
  }

  const calculateOverallProgress = () => {
    if (!dashboard.overallProgress) {
      return 0;
    }
    const total =
      dashboard.overallProgress.reported +
      dashboard.overallProgress.clicked +
      dashboard.overallProgress.missed;
    return Math.round((dashboard.overallProgress.reported / total) * 100);
  };

  return (
    <Grid sx={{ pt: spacings.spacing32 }}>
      <Box>
        <Card name="Overall progress">
          <PieChart
            series={[
              {
                innerRadius: 30,
                data: [
                  {
                    id: 0,
                    value: dashboard?.overallProgress?.reported || 0,
                    label: "Reported",
                    color: color.blue500,
                  },
                  {
                    id: 1,
                    value: dashboard?.overallProgress?.clicked || 0,
                    label: "Clicked",
                    color: "#FF7875",
                  },
                  {
                    id: 2,
                    value: dashboard?.overallProgress?.missed || 0,
                    label: "Missed",
                    color: color.blue200,
                  },
                ],
              },
            ]}
            width={258}
            height={102}
            tooltip={{ trigger: "none" }}
          >
            <PieCenterLabel>{calculateOverallProgress()}%</PieCenterLabel>
          </PieChart>
        </Card>
      </Box>
      <Box sx={{ mt: spacings.spacing32 }}>
        <Card name="Activities">
          <Box
            sx={{ display: "flex", gap: spacings.spacing16, flexWrap: "wrap" }}
          >
            {dashboard?.activity?.map((activity) => (
              <ActivityCard
                date={activity.createdDate}
                content={activity.preparedEmailSubject}
                status={activity.status}
              />
            ))}
          </Box>
        </Card>
      </Box>
    </Grid>
  );
};

interface ActivityCardProps {
  date: Date;
  content: string;
  status: string;
}
const ActivityCard = (props: ActivityCardProps) => {
  let badge;
  switch (props.status) {
    case EMAIL_STATUS.EMAIL_REPORTED:
      badge = <ReportedBadge />;
      break;
    case EMAIL_STATUS.EMAIL_CLICKED:
      badge = <ClickedBadge />;
      break;
    default:
      badge = <MissedBadge />;
      break;
  }

  const formattedDate = new Date(props.date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "58px",
        px: spacings.spacing24,
        py: spacings.spacing12,
        backgroundColor: color.brand50,
        borderRadius: "8px",
      }}
    >
      <Box sx={{}}>
        <Box sx={{ color: color.grey600, fontSize: "14px" }}>
          {formattedDate}
        </Box>
        <Box
          sx={{
            width: {
              desktop: "800px",
              laptop: "532px",
              tablet: "372px",
              mobile: "240px",
            },
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props.content}
        </Box>
      </Box>
      {badge}
      <Box>
        <IconButton>
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  );
};

const badgeStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  px: spacings.spacing8,
  py: spacings.spacing2,
  borderRadius: "24px",
  fontSize: "14px",
  lineHeight: "20px",
};

const ReportedBadge = () => {
  return (
    <Box
      sx={{
        ...badgeStyles,
        backgroundColor: color.blue100,
        color: color.blue500,
      }}
    >
      Reported
    </Box>
  );
};

const ClickedBadge = () => {
  return (
    <Box
      sx={{
        ...badgeStyles,
        backgroundColor: color.red100,
        color: "#FF4D4F",
      }}
    >
      Clicked
    </Box>
  );
};

const MissedBadge = () => {
  return (
    <Box
      sx={{
        ...badgeStyles,
        backgroundColor: color.grey100,
        color: color.grey700,
      }}
    >
      Missed
    </Box>
  );
};

const StyledText = styled("text")(({ theme }) => ({
  fill: color.blue600,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: "18px",
  fontWeight: "500",
  fontFamily: fontFamily.primary,
}));

const PieCenterLabel = ({ children }: { children: React.ReactNode }) => {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
};

export default Dashboard;
