import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, TextField, Box } from "@mui/material";

import { Email } from "api/types";
import { getEmail } from "api/account/email";
import { useLoading } from "components/LoadingProvider";
import { convertUTCToLocalTime } from "utils";
import { COOKIES } from "const";
import { useCookies } from "react-cookie";

const AccountEmailDetail = () => {
  const { emailId } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState<Email | undefined>(undefined);
  const { setIsLoading } = useLoading();
  const [cookie] = useCookies([COOKIES.ACCOUNT_UUID]);

  const fetchEmail = useCallback(async () => {
    if (!emailId) {
      return;
    }
    setIsLoading(true);

    const res = await getEmail(cookie.accountUuid, emailId);
    if (res.status === 200) {
      setEmail(res.data);
    }
    setIsLoading(false);
  }, [setIsLoading, emailId]);

  useEffect(() => {
    fetchEmail();
  }, [fetchEmail]);

  if (!email) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <TextField
              margin="normal"
              label="Email UUID"
              variant="outlined"
              fullWidth
              name="email-uuid"
              value={email.emailUuid}
              disabled
            />
            <TextField
              margin="normal"
              label="Status"
              variant="outlined"
              fullWidth
              value={email.status}
              name="email-status"
            />
            <TextField
              margin="normal"
              label="Phishee Email"
              variant="outlined"
              fullWidth
              value={email.phishee.user.email}
            />
            <TextField
              margin="normal"
              label="Phishee Name"
              variant="outlined"
              fullWidth
              value={`${email.phishee.user.firstName} ${email.phishee.user.lastName}`}
            />
            <TextField
              margin="normal"
              label="Job Function"
              variant="outlined"
              fullWidth
              value={email.phishee.jobFunction}
            />
            <TextField
              margin="normal"
              label="Phishee Created"
              variant="outlined"
              fullWidth
              value={convertUTCToLocalTime(email.phishee.createdDate)}
            />
            <TextField
              margin="normal"
              label="Account Name"
              variant="outlined"
              fullWidth
              value={email.phishee.account.accountName}
            />
            <TextField
              margin="normal"
              label="Account Name"
              variant="outlined"
              fullWidth
              value={email.phishee.account.accountName}
            />
            <TextField
              margin="normal"
              label="Account Created"
              variant="outlined"
              fullWidth
              value={convertUTCToLocalTime(email.phishee.account.createdDate)}
            />
            <TextField
              margin="normal"
              label="Prepared Email Subject"
              variant="outlined"
              fullWidth
              value={email.preparedEmailSubject}
            />
            <TextField
              value={email.preparedEmailBody}
              fullWidth
              disabled
              InputProps={{
                rows: 20,
                multiline: true,
                inputComponent: "textarea",
              }}
            />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Button
              variant="contained"
              onClick={() =>
                navigate(`/account/users/${email.phishee.phisheeUuid}`)
              }
              sx={{ mr: 2 }}
            >
              Open User
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <div dangerouslySetInnerHTML={{ __html: email.preparedEmailBody }} />
        </Grid>
      </Grid>
    </>
  );
};

export default AccountEmailDetail;
