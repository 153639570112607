import * as React from "react";
import { createContext, useContext, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface LoadingContextProps {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const LoadingContext = createContext<LoadingContextProps>({
  isLoading: false,
  setIsLoading: () => {},
});

interface LoadingProviderProps {
  children: React.ReactNode;
}

const LoadingProvider = (props: LoadingProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const value = { isLoading, setIsLoading };
  return (
    <LoadingContext.Provider value={value}>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress size={60} />
        </Backdrop>
      </div>
      {props.children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
};

export { LoadingProvider, useLoading };
