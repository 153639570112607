import React, { useCallback, useEffect, useState } from "react";
import { postResetPassword, redeemResetPassword } from "api/auth";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import LoginIcon from "@mui/icons-material/Login";

const ResetPassword = () => {
  const { tokenUuid } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!tokenUuid) {
      return;
    }
    const res = await redeemResetPassword(tokenUuid, password, confirmPassword);
    if (res.status !== 200) {
      setError("Failed to reset password");
      return;
    }

    navigate("/login");
  };

  return (
    <Container component="main">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          alignItems: "center",
          margin: "auto",
          marginTop: 8,
          padding: 4,
          backgroundColor: "#fff",
          borderRadius: 4,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LoginIcon />
        </Avatar>
        <Typography variant="h5" component="h1" textAlign="center">
          Reset Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "100%" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            autoComplete="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirm-password"
            label="Confirm password"
            type="password"
            name="confirm-passowrd"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && <Typography color="error">{error}</Typography>}
        </Box>
        <Button type="submit" variant="contained" fullWidth>
          Reset Password
        </Button>
        <Button
          variant="text"
          fullWidth
          startIcon={<ArrowBackRoundedIcon />}
          onClick={() => navigate("/login")}
        >
          Back to log in
        </Button>
      </Box>
    </Container>
  );
};

export default ResetPassword;
