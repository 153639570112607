import axios, { AxiosResponse } from "axios";
import { SignUpOuttakeForm } from "types/auth";
import { Account } from "./types";
import { accountConfig } from "./const";

interface ValidationError {
  message: string;
  errors: Record<string, string[]>;
}

interface LoginResponse {
  token: string;
  firstName: string;
  lastName: string;
  accountUuid: string;
  email: string;
}
const onLogin = async (
  email: string,
  password: string
): Promise<AxiosResponse<LoginResponse>> => {
  const url = "/api/v1/auth";
  let res = null;
  try {
    res = await axios.post(url, {
      email: email,
      password: password,
    });

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

const onSignUp = async (form: SignUpOuttakeForm): Promise<string> => {
  const url = "/api/v1/account";
  try {
    const res = await axios.post(url, form);

    return res.data;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
    } else {
      console.error(error);
    }
    return "";
  }
};

const onLogout = async (config = accountConfig) => {
  const url = "/api/v1/auth";
  try {
    const res = await axios.delete(url, config);
    return res.status === 200;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
    } else {
      console.error(error);
    }
    return false;
  }
};

export const getAccountInvite = async (
  inviteId: string
): Promise<AxiosResponse<Account>> => {
  const url = `/api/v1/account-invite/${inviteId}`;
  try {
    const res = await axios.get(url);
    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const postResetPassword = async (email: string) => {
  const url = `/api/v1/reset-password`;
  try {
    const res = await axios.post(url, {
      email: email,
    });
    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const redeemResetPassword = async (
  token: string,
  password: string,
  confirmPassword: string
): Promise<AxiosResponse<null>> => {
  const url = `/api/v1/reset-password`;
  try {
    const res = await axios.put(url, {
      token: token,
      password: password,
      confirmPassword: confirmPassword,
    });
    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
    } else {
      console.error(error);
    }
    throw error;
  }
};

export { onLogin, onSignUp, onLogout };
