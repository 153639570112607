import React, { useState } from "react";
import {
  Container,
  Avatar,
  Button,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { SignUpIntakeForm, SignUpOuttakeForm } from "types/auth";
import { onSignUp } from "api/auth";

const SignUp = () => {
  const [form, setForm] = useState<SignUpIntakeForm>({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
  });
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inviteUuid, setInviteUuid] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form.password !== form.confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setError("");

    const outtakeForm = convertIntakeToOuttake(form);

    const result = await onSignUp(outtakeForm);
    if (!!result) {
      setIsSubmitted(true);
      setInviteUuid(result);
    } else {
      setError("Failed to sign up");
    }
  };

  const convertIntakeToOuttake = (
    intake: SignUpIntakeForm
  ): SignUpOuttakeForm => {
    return {
      username: intake.username,
      email: intake.email,
      password: intake.password,
      firstName: intake.firstName,
      lastName: intake.lastName,
      plan: "free",
    };
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 400,
          padding: 4,
          backgroundColor: "#fff",
          borderRadius: 4,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        {isSubmitted && (
          <>
            <Typography>
              Please verify from your email to activate your account.
            </Typography>
            <div id="invite-uuid" style={{ display: "none" }}>
              {inviteUuid}
            </div>
          </>
        )}
        {!isSubmitted && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="current-password"
              value={form.confirmPassword}
              onChange={(e) =>
                setForm({ ...form, confirmPassword: e.target.value })
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Business Name"
              name="username"
              value={form.username}
              onChange={(e) => setForm({ ...form, username: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="first_name"
              label="First Name"
              name="first_name"
              autoComplete="given-name"
              value={form.firstName}
              onChange={(e) => setForm({ ...form, firstName: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="family-name"
              value={form.lastName}
              onChange={(e) => setForm({ ...form, lastName: e.target.value })}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Typography textAlign="center">
              Already have an account?{" "}
              <a href="/login" style={{ textDecoration: "none" }}>
                Log In
              </a>
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default SignUp;
