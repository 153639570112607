import axios, { AxiosResponse } from "axios";
import { ValidationError, Account, AccountListFilter } from "api/types";
import { adminConfig } from "api/const";

const getAccounts = async (
  filter?: AccountListFilter
): Promise<AxiosResponse<Account[]>> => {
  const url = `/api/v1/account`;
  let res = null;
  try {
    res = await axios.get(url, {
      ...adminConfig,
      params: filter,
    });

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

const getAccount = async (
  accountUuid: string
): Promise<AxiosResponse<Account>> => {
  const url = `/api/v1/account/${accountUuid}`;
  console.log({ url });
  let res = null;
  try {
    res = await axios.get(url, adminConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

interface PostAccountPayload {
  accountName: string;
  plan: string;
  isActive: boolean;
}
const postAccount = async (
  payload: PostAccountPayload
): Promise<AxiosResponse<Account>> => {
  const url = `/api/v1/account`;
  let res = null;
  try {
    const config = {
      ...adminConfig,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    res = await axios.post(url, payload, config);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

const putAccount = async (
  accountUuid: string,
  payload: PostAccountPayload
): Promise<AxiosResponse<Account>> => {
  const url = `/api/v1/account/${accountUuid}`;
  let res = null;
  try {
    const config = {
      ...adminConfig,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    res = await axios.put(url, payload, config);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

const deleteAccount = async (accountUuid: string): Promise<AxiosResponse> => {
  const url = `/api/v1/account/${accountUuid}`;
  let res = null;
  try {
    res = await axios.delete(url, adminConfig);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export { getAccounts, getAccount, postAccount, putAccount, deleteAccount };
