import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import { Menu as MenuIcon } from "@mui/icons-material";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PhishUpIcon from "../Icon/PhishUpIcon";
import * as style from "./styles";

const pages = [
  {
    name: "Log In",
    link: "/login",
  },
];

interface HeaderProps {}

const Header = (props: HeaderProps) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  console.log("header...");
  return (
    <AppBar position="static" sx={style.appBarStyle}>
      <Container sx={{ px: 5, height: "72px" }} disableGutters>
        <Toolbar disableGutters sx={style.toolbarStyle}>
          <PhishUpIcon />

          {/* mobile start */}
          <Box
            sx={{
              flexGrow: 1,
              display: { mobile: "flex", tablet: "none" },
              justifyContent: "end",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map(({ name, link }) => (
                <MenuItem key={name} onClick={() => navigate(link)}>
                  <Typography textAlign="center">{name}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Button
              key={"button-join"}
              onClick={() => navigate("/signup")}
              sx={style.joinButtonStyle}
              variant="contained"
            >
              SIGN UP
            </Button>
          </Box>
          {/* mobile end */}
          {/* Desktop start */}
          <Box
            sx={{
              flexGrow: 1,
              display: { mobile: "none", tablet: "flex" },
              flexDirection: "row-reverse",
            }}
          >
            <Button
              key={"button-join"}
              onClick={() => navigate("/signup")}
              sx={style.joinButtonStyle}
              variant="contained"
            >
              SIGN UP
            </Button>
            {pages.map(({ name, link }) => (
              <Button
                key={name}
                onClick={() => navigate(link)}
                sx={style.menuButtonStyle}
              >
                {name}
              </Button>
            ))}
          </Box>
          {/* Desktop end */}
          <Box sx={{ flexGrow: 0 }}></Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
