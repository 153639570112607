import { Icon, SxProps, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface PhishUpIconProps {
  sx?: SxProps;
  type?: "primary" | "secondary";
  link?: string;
  width?: string;
  height?: string;
}

const PhishUpIcon = ({
  sx,
  type = "primary",
  link,
  width,
  height,
}: PhishUpIconProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const imgPath =
    type === "primary" ? "/media/Logo.svg" : "/media/WhiteLogo.svg";

  const handleClick = () => {
    if (link) {
      return navigate(link);
    }
  };

  const isTablet = useMediaQuery(theme.breakpoints.down("tablet"));
  const getDefaultImgSize = () => {
    if (isTablet) {
      return { width: "74px", height: "28px" };
    } else {
      return { width: "85px", height: "32px" };
    }
  };

  return (
    <Icon
      sx={{
        ...sx,
        cursor: link && "pointer",
        width: width ?? getDefaultImgSize().width,
        height: height ?? getDefaultImgSize().height,
      }}
      onClick={handleClick}
    >
      <img
        src={imgPath}
        alt="logo"
        width={width ?? getDefaultImgSize().width}
        height={height ?? getDefaultImgSize().height}
      />
    </Icon>
  );
};

export default PhishUpIcon;
