import React, { useState } from "react";
import { postResetPassword } from "api/auth";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import LoginIcon from "@mui/icons-material/Login";

const ResetPasswordVerify = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const res = await postResetPassword(email);
    if (res.status !== 200) {
      setError("Failed to send the verification email");
      return;
    }

    setIsSubmitted(true);
  };

  return (
    <Container component="main">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          alignItems: "center",
          margin: "auto",
          marginTop: 8,
          padding: 4,
          backgroundColor: "#fff",
          borderRadius: 4,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LoginIcon />
        </Avatar>
        {isSubmitted && (
          <Typography>
            A verification email has been sent to your email address.
          </Typography>
        )}
        {!isSubmitted && (
          <>
            <Typography variant="h5" component="h1" textAlign="center">
              Reset Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error && <Typography color="error">{error}</Typography>}
            </Box>
            <Button type="submit" variant="contained" fullWidth>
              Send Verification Email
            </Button>
          </>
        )}
        <Button
          variant="text"
          fullWidth
          startIcon={<ArrowBackRoundedIcon />}
          onClick={() => navigate("/login")}
        >
          Back to log in
        </Button>
      </Box>
    </Container>
  );
};

export default ResetPasswordVerify;
