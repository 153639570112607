import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import { Search, PersonAdd } from "@mui/icons-material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useLoading } from "components/LoadingProvider";
import { Box } from "@mui/system";
import * as api from "api/account/phishee";
import { Phishee, PhisheeListFilter } from "api/types";
import { convertUTCToLocalTime, objectToQueryParams } from "utils";
import { COOKIES } from "const";
import { useCookies } from "react-cookie";

const initialFilter = {
  isActive: "all",
} as PhisheeListFilter;

const AccountUserList = () => {
  const [phishees, setPhishees] = useState<Phishee[]>([]);
  const [filter, setFilter] = useState(initialFilter);
  const [cookie] = useCookies([COOKIES.ACCOUNT_UUID]);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const fetchPhishess = useCallback(
    async (currentFilter: PhisheeListFilter) => {
      setIsLoading(true);
      const res = await api.getPhishees(cookie.accountUuid, currentFilter);
      if (res.status === 200) {
        setPhishees(res.data);
      }
      setIsLoading(false);
    },
    [setIsLoading, cookie.accountUuid]
  );

  useEffect(() => {
    fetchPhishess(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPhishess]);

  const handleSearch = (_e: any) => {
    const queryParam = objectToQueryParams(filter);
    navigate(`/account/users?${queryParam}`);
    fetchPhishess(filter);
  };

  const DetailButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/account/users/${params.id}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Edit
      </Button>
    );
  };

  const columns: GridColDef<Phishee>[] = [
    { field: "phisheeId", headerName: "ID", width: 70 },
    {
      field: "Name",
      headerName: "Name",
      width: 200,
      valueGetter: (_params, row) =>
        row?.user?.firstName + " " + row?.user?.lastName,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      valueGetter: (_params, row) => row.user?.email,
    },
    {
      field: "createdDate",
      headerName: "Created",
      width: 120,
      valueFormatter: (_params, row) => convertUTCToLocalTime(row.createdDate),
    },
    {
      field: "isActive",
      type: "boolean",
      headerName: "Active",
      width: 80,
      valueGetter: (_value, row) => row.isActive && row?.user?.isActive,
    },
    {
      field: "jobFunction",
      headerName: "Job Function",
      width: 180,
      valueGetter: (_value, row) => row.jobFunction,
    },
    {
      field: "Edit",
      headerName: "",
      width: 150,
      renderCell: DetailButton,
    },
  ];

  return (
    <>
      <Grid container>
        <Box>
          <Typography variant="h1" gutterBottom>
            User Management
          </Typography>
        </Box>
        <Grid
          container
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          // sx={{ height: "36.5px" }}
        >
          <Box>
            <FormControl sx={{ width: "120px" }}>
              <InputLabel id="demo-simple-select-label">Active</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter.isActive}
                label="Is Active"
                onChange={(e) =>
                  setFilter({ ...filter, isActive: e.target.value })
                }
                sx={{ height: "36.5px" }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"true"}>Active</MenuItem>
                <MenuItem value={"false"}>Inactive</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
              onClick={handleSearch}
              startIcon={<Search />}
            >
              Search
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/account/users/new")}
              startIcon={<PersonAdd />}
              id="add-user"
            >
              Add User
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ paddingBottom: "1rem" }}></Box>
          <Box>
            <DataGrid
              rows={phishees}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50]}
              getRowId={(row) => row.phisheeUuid ?? ""}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountUserList;
