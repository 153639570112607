import { Grid, Typography } from "@mui/material";

export const DesignSystem = () => {
  return (
    <Grid container sx={{ p: 5 }} spacing={2}>
      <Typography variant="display1">Typography</Typography>
      <Grid item xs={12}>
        <Typography variant="smallLabel">Small Label</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="xs">XS</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="sm">SM</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="md">MD</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="lg">LG</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="xl">XL</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1">H1</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">H2</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">H3</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">H4</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">H5</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">H6</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="display1">Display 1</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="display2">Display 2</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="display3">Display 3</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="display4">Display 4</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="display5">Display 5</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="display6">Display 6</Typography>
      </Grid>
    </Grid>
  );
};
