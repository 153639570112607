import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Grid, Typography, Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as api from "api/account/email";
import { Email, EmailListFilter } from "api/types";
import { useLoading } from "components/LoadingProvider";
import { convertUTCToLocalTime } from "utils";
import { useCookies } from "react-cookie";
import { COOKIES } from "const";

const initialFilter = {} as EmailListFilter;

const AccountEmailList = () => {
  const [emails, setEmails] = useState<any>([]);
  const [filter] = useState(initialFilter);
  const { setIsLoading } = useLoading();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [cookie] = useCookies([COOKIES.ACCOUNT_UUID]);

  const fetchEmails = useCallback(
    async (filter: EmailListFilter) => {
      setIsLoading(true);
      const res = await api.getEmails(cookie.accountUuid, filter);
      if (res.status === 200) {
        setEmails(res.data);
      }
      setIsLoading(false);
    },
    [setIsLoading, cookie.accountUuid]
  );

  useEffect(() => {
    var newFilter = { ...filter };
    fetchEmails(newFilter);
  }, [searchParams, fetchEmails, filter]);

  const DetailButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/account/emails/${params.id}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Open
      </Button>
    );
  };

  const columns: GridColDef<Email>[] = [
    { field: "emailId", headerName: "ID", width: 50 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      valueGetter: (_, row) => row?.phishee?.user?.email,
    },
    {
      field: "createdDate",
      headerName: "Created",
      width: 120,
      valueGetter: (_, row) => convertUTCToLocalTime(row.createdDate),
    },
    {
      field: "jobFunction",
      headerName: "Job Function",
      width: 120,
      valueGetter: (_, row) => row?.phishee?.jobFunction,
    },
    {
      field: "Edit",
      headerName: "",
      width: 120,
      renderCell: DetailButton,
    },
  ];

  console.log(emails);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h1" gutterBottom>
              Phish Attempts
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={emails}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50]}
            getRowId={(row) => row.emailUuid}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AccountEmailList;
